import React from "react";
import {MainPage} from "./components/MainPage";
import {ReactChartExample} from "./components/ReactChartExample";

const App = () => {
  const [key, setKey] = React.useState(1);
  return <MainPage key={key} setKey={setKey}/>;
};

export default App;
