import {
  SET_DATASET_ACTION,
  SET_SIMULATION_SPEED,
  SET_SMOOTH_ARRAY,
  SET_TICK_DURATION,
  SET_DRAG_DATA_ACTION,
  SET_CHART_DOTS,
  SET_TICK,
  RESET_APP,
  GET_STEPS_REQUEST,
  GET_STEPS_SUCCESS,
  GET_STEPS_ERROR,
  SET_RANDOM_ORDERS,
  SET_ORDERS_PARAMS,
  SET_ORDER_LIMITS,
  SET_ACTIVE_STREAM,
} from "./consts";
import {getRandomItem} from "../utils/getRandomItem";
import {REPORTING_VENUE_LIST} from "../components/consts";

export const initialState = {
  simulationSpeed: 4,
  smoothArray: [],
  tickDuration: null,
  currentTick: 0,
  dataset: [],
  pointStep: 22,
  start: false,
  dragState: {
    isDrag: false,
    dragOrder: null,
    target: null,
    offsetX: null,
  },
  orders: [
    {
      order_id: "OID1",
      side: "BUY",
      row: null,
      x: null,
      mark: "",
    },
    {
      order_id: "OID2",
      side: "SELL",
      row: null,
      x: null,
      mark: "",
    },
    {
      order_id: "OID3",
      side: "BUY",
      row: null,
      x: null,
      mark: "",
    },
    {
      order_id: "OID4",
      side: "SELL",
      row: null,
      x: null,
      mark: "",
    },
    {
      order_id: "OID5",
      side: "BUY",
      row: null,
      x: null,
      mark: "",
    },
  ],
  screenData: [],
  steps: [],
  trade_posts: {},
  stream_executions: {},
  ranking: {},
  isRequesting: false,
  dataReady: false,
  activeStream: "",
};

export const initReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_STEPS_REQUEST:
      const {orders} = state;
      const activeOrders = orders.filter((e) => e.x);
      return {
        ...state,
        orders: activeOrders,
        isRequesting: true,
      };

    case GET_STEPS_SUCCESS:
      const {orders_initial, steps, stream_executions, trade_posts} = payload;

      const streamExecutionWithVenue = {};

      for (const key in stream_executions) {
        streamExecutionWithVenue[key] = {...stream_executions[key]};
        for (const innerKey in stream_executions[key])
          streamExecutionWithVenue[key][innerKey] = {
            ...stream_executions[key][innerKey],
            venue: getRandomItem(REPORTING_VENUE_LIST),
          };
      }

      const ordersUpd = state.orders.map((el, i) => {
        return {
          ...el,
          ...orders_initial[i],
          x: el.created_at_tp * state.pointStep,
        };
      });

      return {
        ...state,
        orders: ordersUpd,
        steps,
        stream_executions: streamExecutionWithVenue,
        smoothArray: Object.values(trade_posts),
        trade_posts,
        isRequesting: false,
        dataReady: true,
      };

    case GET_STEPS_ERROR:
      return {
        ...state,
        isRequesting: false,
      };

    case SET_RANDOM_ORDERS:
      const ordersTmp = state.orders.map((el, i) => {
        // delete payload[i].created_at_tp;
        return {...el, ...payload[i]};
      });

      const sortedOrders = ordersTmp.sort((a, b) => {
        if (a.x === null) {
          return 1;
        }
        if (b.x === null) {
          return -1;
        }
        return a.x - b.x;
      });

      return {
        ...state,
        orders: sortedOrders,
        isRequesting: false,
      };

    case SET_ORDERS_PARAMS:
      const ordersSized = state.orders.map((el) => ({
        ...el,
        size: Number(payload[el.order_id]?.size),
        strat: payload[el.order_id]?.strat,
      }));

      return {
        ...state,
        orders: ordersSized,
      };
    case "RESET_STEPS":
      return {...state, steps: [], screenData: []};
    case "CHANGE_ORDER_SIDE":
      if (
        (payload.side === "BUY" && state.orders.filter((e) => e.side === "BUY").length === 4) ||
        (payload.side === "SELL" && state.orders.filter((e) => e.side === "SELL").length === 4)
      )
        return {...state};
      const newSideOrders = state.orders.map((e) => {
        if (e.order_id === payload.id) {
          e.side = payload.side;
          if (payload.side === "BUY" && e.row === "line4") {
            e.row = "line1";
          }
          if (payload.side === "SELL" && e.row === "line1") {
            e.row = "line4";
          }
        }
        return e;
      });
      return {
        ...state,
        orders: newSideOrders,
      };

    case SET_ORDER_LIMITS:
      return {
        ...state,
        limits: payload,
      };

    case SET_SIMULATION_SPEED:
      return setSlider(state, payload);

    case SET_SMOOTH_ARRAY:
      return setSmoothArray(state, payload);

    case SET_TICK_DURATION:
      return setTickDuration(state, payload);

    case SET_DATASET_ACTION:
      return setDataset(state, payload);

    case SET_CHART_DOTS:
      return {
        ...state,
        screenData: payload,
      };

    case SET_TICK:
      return {
        ...state,
        currentTick: payload,
      };

    case RESET_APP:
      return initialState;

    case SET_DRAG_DATA_ACTION:
      // const rnd = (min, max) =>
      //   Math.floor(Math.random() * (max - min + 1)) + min;
      const limitEnum = Object.values(state.limits);
      let newOrders = null;
      if (payload.isDrag === false) {
        const {dragOrder, target} = state.dragState;
        const newOrdersState = state.orders.map((order) =>
          order.order_id === dragOrder
            ? {
              ...order,
              order_id: dragOrder,
              side: order.side,
              row: payload.offsetX ? target : null,
              x: payload.offsetX ? payload.offsetX : null,
              created_at_tp: Math.round(payload.offsetX / state.pointStep),
              mark: "",
              limit:
                target === "line1" || target === "line4" ? null : target === "line2" ? limitEnum[1] : limitEnum[0],
            }
            : order
        );
        newOrders = newOrdersState.sort((a, b) => {
          if (a.x === null) {
            return 1;
          }
          if (b.x === null) {
            return -1;
          }
          return a.x - b.x;
        });
      }
      return {
        ...state,
        dragState: {
          ...state.dragState,
          ...payload,
        },
        orders: newOrders || [...state.orders],
      };

    case SET_ACTIVE_STREAM:
      return setActiveStream(state, payload);

    default:
      return state;
  }
};

function setSlider(state, payload) {
  const step = 860 / ((state.dataset?.length / 10) * payload - 1);
  const newOrders = state.orders.map((e) => {
    const newTP = Math.ceil(e.x / step);
    return {...e, created_at_tp: newTP};
  });
  return {
    ...state,
    simulationSpeed: payload,
    pointStep: step,
    orders: newOrders,
  };
}

function setSmoothArray(state, payload) {
  return {
    ...state,
    smoothArray: payload,
  };
}

function setTickDuration(state, payload) {
  return {
    ...state,
    tickDuration: payload,
  };
}

function setDataset(state, payload) {
  return {
    ...state,
    dataset: payload,
    pointStep: 860 / ((payload?.length / 10) * 4 - 1),
  };
}

function setActiveStream(state, payload) {
  return {
    ...state,
    activeStream: payload,
  };
}
