import React, {useCallback, useState} from "react";
import "./styles.css";
import "./StreamDetails/styles.css";
import StreamLine from "./StreamLine";
import Slider from "./Slider";
import Stream from "./Stream";
import {useDispatch, useSelector} from "react-redux";
import {
  setSimulationSpeedAction,
} from "../../entities";
import {
  selectorGetActiveStream,
  selectorGetTickDuration,
} from "../../entities/selectors";
import EmptyStream from "./Stream/EmptyStream";
import {StreamDetails} from "./StreamDetails";
import {bgColors} from '../consts';
import SipLine from "../SipLine";

const Streams = ({
  nbbo,
  start,
  steps,
  currentTick,
  stream_executions,
  simulation,
  chartArray,
}) => {

  const strms = React.useRef([]);

  const stepStreams = steps?.[currentTick]?.streams;
  const streams = Object.keys(stream_executions).map((el) => ({
    [el]: Object.values(stream_executions[el]),
  }));
  const renderStreams = streams
    .map((stream) =>
      Object.keys(stream).map((el) => {
        const tmp = Object.values(stream[el]).map((e) => ({
          sid: el,
          exec_price: e.exec_price,
          exec_qty: e.exec_qty,
          exec_rate: e.exec_rate,
          tp: Number(e.tp_id.replace("TP", "")),
          bid: e.tp_qty,
          offer: e.tp_price,
          is_done: false,
        }));
        return tmp;
      })
    )
    .flat();
  strms.current = [];
  stepStreams?.forEach((s) => {
    strms.current.push(
      renderStreams?.find((rs) => rs?.[0]?.sid === s?.stream_id)
    );
  });

  const dispatch = useDispatch();
  const setSimulationSpeed = useCallback(
    (payload) => dispatch(setSimulationSpeedAction(payload)),
    [dispatch]
  );
  const tickDuration = useSelector(selectorGetTickDuration);
  const activeStream = useSelector(selectorGetActiveStream);

  const currentStreamInfo = stream_executions[activeStream];

  const filteredData = chartArray?.map((item) => {
    return {
      quantity: item.quantity,
      price: item.price,
    };
  });

  return (
    <div className="streams-wrapper">
      <div className="streams-header">
        <div className="streams-header-title">Streams</div>
        <div className="streams-header-info">
          {!simulation && (
            <div className="streams-header-speed">
              <div className="slider-learn">learn</div>
              <Slider onChange={setSimulationSpeed}/>
              <div className="slider-blur">blur</div>
            </div>
          )}
          <div className="streams-header-nbbo">
            <div className="streams-header-nbbo-value">
              {
                <>
                  <span>{nbbo[0]}</span>
                  <span>|</span>
                  <span>{nbbo[1]}</span>
                </>
              }
            </div>
            <div className="streams-header-nbbo-label">NBBO</div>
          </div>
        </div>
      </div>
      <div className="streams-title-sip">
        <div className="streams-title-sip-first">
          <span className="streams-title-sip-first-match">Match</span>
          <span>Rate</span>
          <span>Avg Price</span>
          <span>Executed Qty</span>
        </div>
        <div className="streams-title-sip-second">
          {simulation ? (
            <StreamLine
              start={start}
              active={true}
              startStep={0}
              data={filteredData}
              currentTick={currentTick}
              tickDuration={tickDuration}
              simulation={simulation}
            />
          ) : (
            <SipLine tickDuration={tickDuration}/>
          )}
          <div className="streams-title-sip-label">SIP</div>
        </div>
      </div>
      <div className="streams-table">
        {
          strms?.current.length < 4
            ? (
              strms?.current.concat(
                Array(4 - strms.current.length).fill(null)
              ).map((stream, i) => {
                if (stream && stream[i]?.sid) {
                  return (
                    <Stream
                      key={stream[0]?.sid}
                      start={start}
                      streamData={stream}
                      stepStreams={stepStreams}
                      currentTick={currentTick}
                      tickDuration={tickDuration}
                      simulation={simulation}
                      bgcolor={bgColors[stream?.[0]?.sid]}
                    />
                  )
                } else {
                  return (
                    <EmptyStream index={i}/>
                  )
                }
              })
            ) : (
              strms.current.map(stream => (
                <Stream
                  key={stream[0]?.sid}
                  start={start}
                  streamData={stream}
                  stepStreams={stepStreams}
                  currentTick={currentTick}
                  tickDuration={tickDuration}
                  simulation={simulation}
                  bgcolor={bgColors[stream?.[0]?.sid]}
                />
              ))
            )
        }
      </div>
      {
        activeStream &&
        <StreamDetails
          details={currentStreamInfo}
          id={activeStream}
          currentTick={currentTick}
        />
      }
    </div>
  );
};

export default React.memo(Streams);
