import styled from "styled-components";
import ReactSlider from "react-slider";
import React, {useEffect} from "react";
import SliderIcon from "../../svg/SliderIcon";
import {useSelector} from "react-redux";
import {selectorGetSimulationSpeed} from "../../entities";

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 25px;
  margin: 0 7px;
  position: absolute;
  z-index: 1;
  bottom: 1px;
  right: 12px;
  cursor: pointer;
`;

const StyledThumb = styled.div`
  top: 9px;
  height: 9px;
  line-height: 25px;
  width: 28px;
  text-align: center;
  color: #fff;
  background: linear-gradient(180deg,
  rgba(228, 228, 228, 0) 0%,
  rgba(0, 0, 0, 0.6) 100%),
  #e4e4e4;
  border-radius: 2px;
  font-size: 7px;

  &:focus {
    outline: none;
  }
`;

const StyledTrack = styled.div`
  top: 9px;
  bottom: 0;
  background: ${(props) => (props.index === 1 ? "#192432" : "#4980D9")};
  box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  height: 9px;
`;

const StyledBorder = styled.div`
  position: absolute;
  z-index: 0;
  background: #333d59;
  border: 7px solid #333d59;
  border-radius: 32px;
  height: 9px;
  width: 227px;
`;

const Thumb = (props, state) => (
  <StyledThumb {...props}>
    <SliderIcon/>
  </StyledThumb>
);

const Track = (props, state) => {
  return (
    <StyledTrack {...props} index={state.index}/>
  );
};

export default function Slider({onChange}) {
  const event = new CustomEvent('resize', {detail: {}, bubbles: true, cancelable: true})
  useEffect(() => {
    document.dispatchEvent(event);
  });
  const simulationSpeed = useSelector(selectorGetSimulationSpeed);

  return (
    <>
      <StyledSlider
        defaultValue={simulationSpeed}
        min={1}
        max={10}
        renderTrack={Track}
        renderThumb={Thumb}
        onChange={(value) => onChange(value)}
      />
      <StyledBorder/>
    </>
  );
}