import React, {useState} from "react";
import {Order} from "./index.jsx";
import {useDispatch} from "react-redux";
import "./styles.css";

export const OrderWithSelector = (props) => {
  const {side, id, x, num, simulation} = props;
  const [sideSelector, setShowSelector] = useState(false);

  const dispatch = useDispatch();

  const showSideSelector = (e) => setShowSelector((state) => !state);
  const hideSideSelector = (e) => setShowSelector(false);

  const selectSide = (e) =>
    dispatch({
      type: "CHANGE_ORDER_SIDE",
      payload: {side: side === "SELL" ? "BUY" : "SELL", id},
    });

  return (
    <div
      className="orders-order-wrapper"
      onClick={showSideSelector}
      onMouseLeave={hideSideSelector}
    >
      <Order {...props} num={x ? num : ""} x={null}/>
      {sideSelector && !simulation && (
        <div className="side-selector-wrapper">
          {side === "SELL" && (
            <div id="BUY" onClick={selectSide}>
              <Order {...props} side="BUY" num={x ? num : null} x={null}/>
            </div>
          )}
          {side === "BUY" && (
            <div id="SELL" onClick={selectSide}>
              <Order {...props} side="SELL" num={x ? num : null} x={null}/>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
