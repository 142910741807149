export const bgColors = {
  SID1: "#086479",
  SID2: "#4964C8",
  SID3: "#584280",
  SID4: "#9b6541",
  SID5: "#1e337e",
  SID6: "#88130c",
  SID7: "#440c33",
  SID8: "#370c44",
  SID9: "#3c440c",
  SID10: "#086479",
  SID11: "#4964C8",
  SID12: "#584280",
  SID13: "#9b6541",
  SID14: "#1e337e",
  SID15: "#88130c",
  SID16: "#440c33",
  SID17: "#370c44",
  SID18: "#3c440c",
  SID19: "#086479",
  SID20: "#4964C8",
  SID21: "#584280",
  SID22: "#9b6541",
  SID23: "#1e337e",
  SID24: "#88130c",
  SID25: "#440c33",
  SID26: "#370c44",
  SID27: "#3c440c",
  SID28: "#086479",
  SID29: "#4964C8",
  SID30: "#584280",
  SID31: "#9b6541",
  SID32: "#1e337e",
  SID33: "#88130c",
  SID34: "#440c33",
  SID35: "#370c44",
  SID36: "#3c440c",
  SID37: "#086479",
  SID38: "#4964C8",
  SID39: "#584280",
  SID40: "#9b6541",
};

export const REPORTING_VENUE_LIST = [
  "BYX",
  "BZX",
  "EDGA",
  "EDGX",
  "CBOE",
  "PHLX",
  "Nasdaq",
  "Nasdaq BX",
  "ISE",
  "NYSE",
  "ARCA",
  "American",
  "National",
  "Chicago",
  "TRF",
  "IEX",
  "LTSE",
  "MEMX",
  "MIAX",
]