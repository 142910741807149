import React from 'react';

export const RankingArrows = ({rotate = 0, marginRight = 0}) => {
  return (
    <svg width="59" height="43" viewBox="0 0 59 43" fill="none" xmlns="http://www.w3.org/2000/svg"
         style={{transform: `rotate(${rotate}deg)`, marginRight: `${marginRight}px`}}>
      <g opacity="0.1" clipPath="url(#clip0)">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M19.2957 0L-0.0114365 21.5L19.2957 43H26.582L8.94259 21.5L26.582 0H19.2957Z" fill="white"/>
      </g>
      <g opacity="0.04" clipPath="url(#clip1)">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M35.2449 0L15.9378 21.5L35.2449 43H42.5312L24.8918 21.5L42.5312 0H35.2449Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="26.5823" height="43" fill="white" transform="matrix(-1 0 0 1 26.582 0)"/>
        </clipPath>
        <clipPath id="clip1">
          <rect width="26.5823" height="43" fill="white" transform="matrix(-1 0 0 1 42.5312 0)"/>
        </clipPath>
      </defs>
    </svg>
  );
}
