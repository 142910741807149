import React, {createRef, useState, useEffect, useCallback} from "react";
import cloneDeep from "lodash/cloneDeep";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {
  getSteps,
  setOrderLimits,
  setDatasetAction,
  setSmoothArrayAction,
  setTickDuration,
  setChartDots,
  setRandomOrders,
  setTick,
  resetApp,
  setActiveStream,
} from "../../entities/actions";
import {selectorGetSimulationSpeed} from "../../entities";
import {selectorGetDataset} from "../../entities/selectors";
import {Ranking} from "../Ranking";
import {Timetable} from "../Timetable";
import {FooterButtons} from "../FooterButtons";
import Streams from "../Streams";
import {Orders} from "../Orders";
import "./styles.css";
import {api} from "../../services/api/const";

export const MainPage = ({setKey}) => {
  const dispatch = useDispatch();
  const ref = createRef(null);

  const dataset = useSelector(selectorGetDataset);
  const simulationSpeed = useSelector(selectorGetSimulationSpeed);
  const screenData = useSelector((state) => state.init.screenData);
  const currentTick = useSelector((state) => state.init.currentTick);
  const chartArray = useSelector((state) => state.init.smoothArray);
  const orders = useSelector((state) => state.init.orders);
  const limits = useSelector((state) => state.init.limits);
  const pointStep = useSelector((state) => state.init.pointStep);
  const stepsAll = useSelector((state) => state.init.steps);
  const stream_executions = useSelector((state) => state.init.stream_executions);
  const setDatasetToStore = useCallback((payload) => dispatch(setDatasetAction(payload)), [dispatch]);
  const setTickDurationToStore = useCallback((payload) => dispatch(setTickDuration(payload)), [dispatch]);
  const setSmoothArrayToStore = useCallback((payload) => dispatch(setSmoothArrayAction(payload)), [dispatch]);

  const [dots, setDots] = useState([]);
  const [minValue, setMin] = useState(null);
  const [maxValue, setMax] = useState(null);
  const [scale, setScale] = useState(null);
  const [start, setStart] = useState(false);
  const [simulation, startSimulation] = useState(false);
  const [tick, setTickState] = useState(0);
  const [delay, setDelay] = useState(1000);
  const [tint, setTint] = useState(true);
  const [dSet, setDataset] = useState("DS101-N");
  const chartDots = screenData || [];
  const currentRate = chartArray[currentTick];
  const isSimulationFinished = currentTick === chartArray.length - 1 && chartArray.length;

  const getRandomOrders = (lowerLimit, upperLimit) => {
    const rnd = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
    const sideEnum = ["SELL", "BUY"];
    const stratEnum = ["15%", "30%", "200%", "LS"];
    // const limitEnum = [lowerLimit, upperLimit, null];
    const orders = [];
    for (let i = 0; i < 5; i += 1) {
      const size = rnd(5, 50) * 1000;
      const side = i === 0 ? sideEnum[1] : i === 4 ? sideEnum[0] : sideEnum[rnd(0, 1)];
      const stratEdge = orders.find((e) => e.strat === "LS") ? 2 : 3;
      const strat = stratEnum[rnd(0, stratEdge)];
      // const limitEdge = orders.find(e => e.limit === null) ? 1 : 2
      // const limit = limitEnum[rnd(0, 2)];
      orders.push({
        side,
        size,
        strat,
        // limit,
      });
    }
    return orders;
  };

  const activeOrders = stepsAll[currentTick]?.streams
    .filter((el) => !el.is_done)
    .map((e) => ({sid: e.stream_id, orders: Object.values(e.orders_by_side)}))
    .reduce((acc, el) => {
      acc[el.orders[0]] = {
        ...acc[el.orders[0]],
        [el.sid]: el.orders[1].slice(3),
      };
      acc[el.orders[1]] = {
        ...acc[el.orders[1]],
        [el.sid]: el.orders[0].slice(3),
      };
      return acc;
    }, {});

  useEffect(async () => {
    const rnd = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
    const ds = `DS10${rnd(1, 8)}-N`;
    setDataset(ds);
    try {
      var result = await axios(`${api}/datasets/`);
    } catch (error) {
      console.log("error", error);
    }
    const lower = result?.data[ds]?.LOWER_LIMIT;
    const upper = result?.data[ds]?.UPPER_LIMIT;
    setDatasetToStore(result?.data[ds].DATA.slice(0));
    dispatch(setRandomOrders(getRandomOrders(lower, upper)));
    dispatch(setOrderLimits({lower, upper}));
    setTint(false);
  }, []);

  const randomize = (lowerLimit, upperLimit) => {
    const rnd = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
    const getXPos = (x, pointStep, orders = []) => {
      let xPos = Math.round(
        (x % pointStep) - pointStep / 2 >= 0 ? x + pointStep - (x % pointStep) : x - (x % pointStep)
      );
      do {
        orders.some((e) => e.x === xPos) && xPos + pointStep >= 860
          ? (xPos = xPos - pointStep * 5)
          : (xPos = xPos + pointStep);
        // console.log("inner", xPos);
      } while (orders.some((e) => e.x === xPos));
      // console.log("xPos", xPos);
      return xPos;
      // const getTP = (x, pointStep) =>  x % pointStep - pointStep / 2 >= 0 ? x + pointStep - x % pointStep : x - x % pointStep
    };

    const limitEnum = [null, null, upperLimit, lowerLimit, null];

    const rndOrders = orders.map((e, i, arr) => {
      const xPos = (i + 0.5) * 80; // getXPos(rnd(pointStep, 400), pointStep);
      const line = e.side === "BUY" ? "line" + rnd(1, 3) : "line" + rnd(2, 4);

      const limit = limitEnum[line.slice(4)];
      return {
        ...e,
        x: xPos,
        row: line,
        limit,
        created_at_tp: Math.round(xPos / pointStep),
      };
    });

    if (!rndOrders.find((e) => e.side === "BUY" && e.row === "line1")) {
      const order = rndOrders.find((e) => e.side === "BUY");
      order.row = "line1";
      order.limit = null;
    }
    if (!rndOrders.find((e) => e.side === "SELL" && e.row === "line4")) {
      const order = rndOrders.find((e) => e.side === "SELL");
      order.row = "line4";
      order.limit = null;
    }

    dispatch(setRandomOrders(rndOrders));
  };

  useEffect(() => {
    let interval;
    if (start && stepsAll?.length > 0) {
      interval = setInterval(() => {
        if (tick < chartArray?.length - 1) {
          setTickState((tick) => (tick < chartArray?.length - 1 ? tick + 1 : tick));
        } else {
          setStart(false);
        }
      }, delay);
    }
    return () => clearInterval(interval);
  }, [start, stepsAll?.length]);

  useEffect(() => {
    dispatch(setTick(tick));
    if (tick === chartArray?.length - 1) setStart(false);
  }, [tick]);

  useEffect(() => {
    if (simulation) {
      setTint(true);
      const ordersState = orders
        .filter((e) => e.x)
        .map((e) => {
          const {side, size, strat, limit, created_at_tp} = e;
          return {
            type: "ON",
            size,
            side,
            strat,
            limit,
            created_at_tp,
          };
        });

      const body = cloneDeep(chartArray);
      ordersState.forEach((order, i) => body.splice(order.created_at_tp + i + 1, 0, order));
      const data = body.map((e) => {
        const {created_at_tp, ...tmp} = e;
        return tmp;
      });

      dispatch(getSteps(data));
    }
  }, [simulation]);

  useEffect(() => {
    if (stepsAll?.length) {
      setTint(false);
    }
  }, [stepsAll?.length]);

  const startStop = () => {
    const activeOrders = orders.filter((e) => e.x);
    if (
      activeOrders.length < 2 ||
      !activeOrders.some((e) => e.side === "BUY") ||
      !activeOrders.some((e) => e.side === "SELL")
    )
      return;
    if (!start) {
      setStart(true);
      startSimulation(true);
    }
    if (start) setStart(false);
  };

  useEffect(() => {
    const getMinValue = () => {
      let minValue = chartArray[0]?.price;

      chartArray?.forEach((item) => {
        if (minValue > item.price) {
          minValue = item.price;
        }
      });
      return minValue;
    };

    const getMaxValue = () => {
      let maxValue = chartArray[0]?.price;

      chartArray?.forEach((item) => {
        if (maxValue < item.price) {
          maxValue = item.price;
        }
      });
      return maxValue;
    };
    if (chartArray?.length) {
      setMin(getMinValue());
      setMax(getMaxValue());
      setDelay(30000 / chartArray?.length);
    }
  }, [chartArray]);

  useEffect(() => {
    if (chartDots?.length / 2 === chartArray?.length) {
      setStart(false);
    }
  }, [chartDots?.length]);

  useEffect(() => {
    const getScale = () => {
      const shift = (limits.upper - limits.lower) / 2;
      const scale = Math.round(230 / ((limits.upper + shift - (limits.lower - shift)) * 100));
      return scale;
    };
    if (minValue !== null && maxValue !== null) {
      setScale(getScale());
    }
  }, [minValue, maxValue]);

  useEffect(() => {
    if (dataset?.length) {
      const chartArray = dataset.slice(0, Math.round(dataset?.length / 10) * simulationSpeed);
      setTickDurationToStore(Math.round(30000 / ((dataset?.length / 100) * simulationSpeed * 10)));
      setSmoothArrayToStore(chartArray);
    }
  }, [dataset?.length, simulationSpeed]);

  useEffect(() => {
    if (!limits?.upper || !limits.lower) return;
    const shift = (limits.upper - limits.lower) / 2;
    const chartDots = chartArray
      .map((e, i) => [
        i * (860 / (chartArray?.length - 1)),
        limits.upper + shift - chartArray[i].price < 0
          ? 0
          : chartArray[i].price < limits.lower - shift
          ? 230
          : (limits.upper + shift - chartArray[i].price) * 100 * scale,
      ])
      .flat();
    dispatch(setChartDots(chartDots));
  }, [limits, scale, chartArray]);

  useEffect(() => {
    setDots(chartDots.slice(0, (currentTick + 1) * 2));
  }, [currentTick]);

  const stepBack = () => {
    if (currentTick > 0 && !start) setTickState((tick) => tick - 1);
  };

  const stepForward = () => {
    if (currentTick < chartArray?.length - 1 && !start) setTickState((tick) => tick + 1);
  };

  const randomizeOrders = () => {
    randomize(limits.lower, limits.upper);
  };

  const reset = () => {
    setKey(Math.random());
    dispatch(resetApp());
  };

  const isOrdersNull = (order) => {
    return order.x === null;
  };

  const replay = () => {
    setStart(false);
    setTickState(0);
    startSimulation(false);
    const chartArray = dataset.slice(0, Math.round(dataset?.length / 10) * simulationSpeed);
    setTickDurationToStore(Math.round(30000 / ((dataset?.length / 100) * simulationSpeed * 10)));
    setSmoothArrayToStore(chartArray);
    dispatch({type: "RESET_STEPS"});
    dispatch(setActiveStream(null));
  };

  return (
    <>
      <div className="body" ref={ref}>
        {tint && (
          <div className="tint-cover">
            <div className="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <div className="wrapper">
          <div className="ranking-and-timetable">
            <Ranking currentTick={currentTick} steps={stepsAll}/>
            <Timetable
              chartArray={chartArray}
              start={start}
              simulationSpeed={simulationSpeed}
              chartDots={dots}
              simulation={simulation}
              currentTick={currentTick}
              steps={stepsAll}
              randomize={randomizeOrders}
              stepBack={stepBack}
              stepForward={stepForward}
              startStop={startStop}
              maxMin={{min: minValue, max: maxValue}}
              activeOrders={activeOrders}
            />
          </div>
          <Streams
            nbbo={[currentRate?.bid || "--", currentRate?.offer || "--"]}
            start={start}
            elQty={chartArray?.length}
            delay={delay}
            currentTick={currentTick}
            steps={stepsAll}
            simulation={simulation}
            stream_executions={stream_executions}
            chartArray={chartArray}
            startStop={startStop}
          />
          {!orders.every(isOrdersNull) && (
            <Orders currentTick={currentTick} steps={stepsAll} orders={orders} simulation={simulation}/>
          )}
          {isSimulationFinished && <FooterButtons replay={replay} reset={reset}/>}
        </div>
      </div>
      <footer>
        <div className="footer-top">
          <span></span>
        </div>
        <div className="footer-bottom">
          <span>
            Disclaimer: The simulations generated are for instructional purposes for institutional traders only, and
            actual trading results may vary from simulated results.
          </span>
        </div>
       </footer>
      <div className="resolution-info">You need to use screen resolution more than 1280x768</div>
    </>
  );
};
