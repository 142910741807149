import React from 'react';

export default function RankingLoader() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.7926 13.5044C16.5581 13.903 16.2182 14.1638 15.7727 14.2869C15.3273 14.41 14.9023 14.3543 14.4979 14.1198C14.0935 13.8854 13.8297 13.5425 13.7066 13.0912C13.5835 12.6399 13.6392 12.2149 13.8737 11.8163C14.5887 10.5738 14.9873 9.24617 15.0694 7.8336C15.1514 6.42104 14.9199 5.07587 14.3748 3.79811C13.8297 2.52035 13.0238 1.42429 11.957 0.509932C12.508 0.697493 13.0238 0.931944 13.5044 1.21329C14.5594 1.82286 15.4503 2.6112 16.1771 3.57831C16.9039 4.54542 17.4139 5.57701 17.7069 6.67307C18 7.76913 18.0733 8.91794 17.9267 10.1195C17.7802 11.3211 17.4021 12.4494 16.7926 13.5044ZM13.5044 16.7926C12.4494 17.4021 11.3211 17.7802 10.1195 17.9267C8.91794 18.0733 7.76913 18 6.67307 17.7069C5.57701 17.4139 4.54542 16.9039 3.57831 16.1771C2.6112 15.4503 1.82286 14.5594 1.21329 13.5044C0.978834 13.1058 0.920221 12.6809 1.03745 12.2296C1.15467 11.7782 1.4155 11.4354 1.81993 11.2009C2.22436 10.9665 2.65223 10.9108 3.10355 11.0339C3.55487 11.157 3.89775 11.4178 4.1322 11.8163C4.67144 12.7542 5.35135 13.563 6.17193 14.2429C6.99251 14.9228 7.87756 15.4298 8.82709 15.7639C9.77662 16.098 10.776 16.2797 11.8251 16.309C12.8743 16.3383 13.8912 16.1713 14.8759 15.8079C14.4422 16.1947 13.985 16.523 13.5044 16.7926ZM9.00293 3.3761C7.56106 3.3761 6.21003 3.69554 4.94985 4.33442C3.68968 4.9733 2.64344 5.84663 1.81114 6.95441C0.978834 8.06219 0.427874 9.30772 0.158255 10.691C0.0527515 10.1166 0 9.55389 0 9.00293C0 7.78378 0.237382 6.61739 0.712146 5.50374C1.18691 4.3901 1.82579 3.43178 2.62879 2.62879C3.43178 1.82579 4.3901 1.18691 5.50374 0.712146C6.61739 0.237382 7.78378 0 9.00293 0C9.47183 0 9.8704 0.164116 10.1986 0.492348C10.5269 0.82058 10.691 1.21915 10.691 1.68805C10.691 2.15695 10.5269 2.55552 10.1986 2.88375C9.8704 3.21198 9.47183 3.3761 9.00293 3.3761Z"
        fill="white"/>
    </svg>
  );
}
