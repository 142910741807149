import React, {useState, useEffect} from "react";
import "./styles.css";

const StreamLine = ({
  start,
  startStep,
  active,
  data,
  currentTick,
  tickDuration,
  simulation,
  bgcolor = "#383B61",
}) => {
  const [left, setLeft] = useState(0);

  useEffect(() => {
    if (data?.length && simulation)
      setLeft(-135 - 135 * (currentTick - startStep));
  });

  return (
    <div
      className="streams-streamline__wrapper"
      style={{
        backgroundColor: active ? bgcolor : "#383B61",
        opacity: active ? 1 : 0.6,
        transition: "background-color 1s ease-out, opacity 1s ease-out",
      }}
    >
      <div
        className="streams-streamline__innerwrapper"
        style={{
          left,
          transition: `left ${
            currentTick + 1 === startStep ? 0 : start ? tickDuration : 200
          }ms linear`,
        }}
      >
        {[<div style={{width: 672}}/>].concat(
          data?.map((item, index) => (
            <div key={index} className="streamline-item">{`${item.exec_qty ||
            item.quantity} - ${item.exec_price || item.price}`}</div>
          ))
        )}
      </div>
    </div>
  );
};

export default StreamLine;
