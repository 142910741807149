import React from "react";
import StreamAction from "../../../svg/StreamAction";
import StreamLine from "./../StreamLine";
import './styles.css';

const EmptyStream = ({index}) => (
  <div
    className={`streams-table-item streams-table-item-opened`}
    style={{
      opacity: 0.6,
    }}
  >
    <div className="streams-table-item-first"/>
    <div
      className="streams-table-item-second"
      style={{
        backgroundColor: '#383B61',
      }}
    >
      <StreamLine/>
      <div className="streams-table-item-second-actions">
          <span
            className="streams-table-item-stream-number"
            style={{
              background: "#2D395A",
              color: "#4E5F8D",
            }}
          >
            <div className="streams-table-item-stream-number-tint">
              Stream
              <div
                className="stream-item-number-circle"
                style={{
                  background: "rgba(78, 95, 141, 0.28)",
                  color: "#4E5F8D",
                }}
              >
                {index + 1}
              </div>
            </div>
          </span>
      </div>
    </div>
    <div
      className="streams-table-item-stream-action"
      style={{background: "#2D395A"}}
    >
      <div className="stream-info-wrapper">
        <StreamAction color="#4E5F8D"/>
      </div>
    </div>
  </div>
);
export default React.memo(EmptyStream);
