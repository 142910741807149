import {put, takeEvery} from 'redux-saga/effects'
import axios from "axios";
import {GET_STEPS_REQUEST, GET_STEPS_SUCCESS, GET_STEPS_ERROR} from './consts'
import {api} from "../services/api/const";


const req = async (data) => await axios({
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    url: `${api}/simulate/events/`,
    data,
  }
);

function* getSteps({payload}) {
  try {
    const response = yield req(payload)
    yield put({type: GET_STEPS_SUCCESS, payload: response.data})
  } catch (error) {
    if (error.response) {
      yield put({type: GET_STEPS_ERROR, error: error.response.data.error})
    }
  }
}

function* stepsWatcher() {
  yield takeEvery(GET_STEPS_REQUEST, getSteps)
}

export default stepsWatcher