import React from 'react';

export default function StreamAction({color}) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 2C12.163 2 12.7989 2.26339 13.2678 2.73223C13.7366 3.20107 14 3.83696 14 4.5V6.257C13.0156 5.44269 11.7775 4.99806 10.5 5H13V4.5C13 4.10218 12.842 3.72064 12.5607 3.43934C12.2794 3.15804 11.8978 3 11.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V5H10.5C9.323 5 8.232 5.37 7.337 6H3V11.5C3 11.8978 3.15804 12.2794 3.43934 12.5607C3.72064 12.842 4.10218 13 4.5 13H5.6C5.783 13.358 6.004 13.693 6.257 14H4.5C3.83696 14 3.20107 13.7366 2.73223 13.2678C2.26339 12.7989 2 12.163 2 11.5V4.5C2 3.83696 2.26339 3.20107 2.73223 2.73223C3.20107 2.26339 3.83696 2 4.5 2H11.5Z"
        fill={color}/>
      <path
        d="M15 10.5C15 11.0909 14.8836 11.6761 14.6575 12.2221C14.4313 12.768 14.0998 13.2641 13.682 13.682C13.2641 14.0998 12.768 14.4313 12.2221 14.6575C11.6761 14.8836 11.0909 15 10.5 15C9.90905 15 9.32389 14.8836 8.77792 14.6575C8.23196 14.4313 7.73588 14.0998 7.31802 13.682C6.90016 13.2641 6.56869 12.768 6.34254 12.2221C6.1164 11.6761 6 11.0909 6 10.5C6 9.30653 6.47411 8.16193 7.31802 7.31802C8.16193 6.47411 9.30653 6 10.5 6C11.6935 6 12.8381 6.47411 13.682 7.31802C14.5259 8.16193 15 9.30653 15 10.5ZM10.5 12.38C10.3342 12.38 10.1753 12.4458 10.0581 12.5631C9.94085 12.6803 9.875 12.8392 9.875 13.005C9.875 13.1708 9.94085 13.3297 10.0581 13.4469C10.1753 13.5642 10.3342 13.63 10.5 13.63C10.6658 13.63 10.8247 13.5642 10.9419 13.4469C11.0592 13.3297 11.125 13.1708 11.125 13.005C11.125 12.8392 11.0592 12.6803 10.9419 12.5631C10.8247 12.4458 10.6658 12.38 10.5 12.38ZM10.5 7.503C9.452 7.503 8.636 8.321 8.647 9.458C8.64833 9.59061 8.70228 9.71726 8.79698 9.81009C8.89169 9.90292 9.01939 9.95433 9.152 9.953C9.28461 9.95167 9.41126 9.89772 9.50409 9.80302C9.59692 9.70831 9.64833 9.58061 9.647 9.448C9.641 8.869 10.007 8.503 10.5 8.503C10.972 8.503 11.353 8.895 11.353 9.453C11.353 9.655 11.283 9.768 10.993 9.997L10.716 10.212C10.21 10.616 10 10.929 10 11.5C9.99977 11.6249 10.0463 11.7455 10.1305 11.8378C10.2147 11.9301 10.3304 11.9876 10.4548 11.9989C10.5793 12.0102 10.7034 11.9745 10.8028 11.8988C10.9023 11.8231 10.9697 11.7129 10.992 11.59L11.003 11.434C11.02 11.286 11.103 11.18 11.349 10.986L11.626 10.771C12.139 10.361 12.353 10.039 12.353 9.453C12.353 8.349 11.531 7.503 10.5 7.503Z"
        fill={color}/>
    </svg>
  );
}


export const StreamClose = () => {
  return (
    <svg width="9" height="16" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M3.76902 4.5L0 8.26902L0.730981 9L4.5 5.23098L8.26902 9L9 8.26902L5.23098 4.5L9 0.730981L8.26902 0L4.5 3.76902L0.730981 0L0 0.730981L3.76902 4.5Z"
            fill="white"/>
    </svg>

  );
}
