import React, {useEffect, useState} from "react";
import {Order} from "../common/Order/ProgressOrder";
import {useSelector} from "react-redux";
import "./styles.css";
import {RankingArrows} from "../../svg/RankingArrows";
import RankingLoader from "../../svg/RankingLoader";
import {selectorGetTradePosts} from "../../entities/selectors";

export const Ranking = ({currentTick, steps}) => {
  const [isLsHack, setIsLsHack] = useState(false);
  const orders = useSelector((state) => state.init.orders);
  const isMatch = steps?.[currentTick]?.ranking.BUY.length && steps?.[currentTick]?.ranking.SELL.length;
  const isMatchClassName = isMatch ? "is-match" : "";

  const streamList = steps[currentTick]?.streams;
  const rankingList = steps[currentTick]?.ranking;

  const rankingListMatch = {
    buy: rankingList?.BUY[0],
    sell: rankingList?.SELL[0]
  };
  const streamListMatch = streamList?.map(item => {
    return {
      side: item.orders_by_side,
      sid: item.stream_id,
    }
  });
  const actualArray = streamListMatch?.filter((item) => {
    return item.side.BUY === rankingListMatch.buy && item.side.SELL === rankingListMatch.sell
  });
  const streamColorClassName = actualArray && actualArray[0]?.sid;

  const tp = useSelector(selectorGetTradePosts);
  const isBlockList = Object.values(tp).filter(item => item.is_block === true);

  const matchRate = (orderSide) => {
    let rate = actualArray && orders?.find(item => item.order_id === actualArray[0]?.side[orderSide])?.max_cap;
    return rate === "LS" ? rate : rate?.slice(0, -1);
  };

  useEffect(() => {
    setIsLsHack(false);
    isBlockList.forEach((item) => {
      if (Number(item.tp_id.slice(2) - 1) === currentTick) {
        setIsLsHack(true);
      }
    })
  }, [currentTick]);

  const filteredLsOrder = orders?.find(item => item.max_cap === "LS");
  const lsOrder = steps[currentTick]?.orders?.find(item => item.order_id === filteredLsOrder?.order_id);
  const lsIsMarketable = () => {
    const ls = orders.find(item => item.max_cap === "LS");
    return lsOrder?.is_marketable && lsOrder?.executed_qty < ls.size;
  }

  return (
    <div className="ranking-wrapper">
      <div className="title">Prioritization</div>
      <div className="ranking-table-wrapper">
        <div className="ranking-side">
          <span>Buy</span>
          <span>Sell</span>
        </div>

        <div className="ranking-table">
          {
            (isLsHack && lsIsMarketable()) && (
              <div className={`ranking-table-side match ls-hack`}>
                <div className="match-arrows">
                  <RankingArrows rotate={180} marginRight={6}/>
                  <RankingArrows/>
                </div>
                <div className="match-loader">
                  <RankingLoader/>
                </div>
                <div className="match-orders">
                  <Order
                    key={filteredLsOrder?.side === "BUY" ? filteredLsOrder?.order_id : "LS-Hack"}
                    id={filteredLsOrder?.side === "BUY" ? filteredLsOrder?.order_id : "LS-Hack"}
                    progress={"LS"}
                    ls={true}
                    side="BUY"
                    isActive={true}
                    iconSize={35}
                    num={filteredLsOrder?.side === "BUY" ? filteredLsOrder?.order_id.substr(3, 1) : "LS"}
                  />
                  <Order
                    key={filteredLsOrder?.side === "SELL" ? filteredLsOrder?.order_id : "LS-Hack"}
                    id={filteredLsOrder?.side === "SELL" ? filteredLsOrder?.order_id : "LS-Hack"}
                    progress={"LS"}
                    ls={true}
                    side="SELL"
                    isActive={true}
                    iconSize={35}
                    num={filteredLsOrder?.side === "SELL" ? filteredLsOrder?.order_id.substr(3, 1) : "LS"}
                  />
                </div>
              </div>
            )
          }
          {
            actualArray?.length ? (
              <div className={`ranking-table-side match ${streamColorClassName}`}>
                <div className="match-arrows">
                  <RankingArrows rotate={180} marginRight={6}/>
                  <RankingArrows/>
                </div>
                <div className="match-loader">
                  <RankingLoader/>
                </div>
                <div className="match-orders">
                  <Order
                    key={actualArray[0]?.side.BUY}
                    id={actualArray[0]?.side.BUY}
                    progress={matchRate("BUY")}
                    ls={matchRate("BUY") === "LS"}
                    side="BUY"
                    isActive={true}
                    iconSize={35}
                    num={actualArray[0]?.side.BUY.substr(3, 1)}
                  />
                  <Order
                    key={actualArray[0]?.side.SELL}
                    id={actualArray[0]?.side.SELL}
                    progress={matchRate("SELL")}
                    ls={matchRate("SELL") === "LS"}
                    side="SELL"
                    isActive={true}
                    iconSize={35}
                    num={actualArray[0]?.side.SELL.substr(3, 1)}
                  />
                </div>
              </div>
            ) : null
          }
          <div className="ranking-table-side not-match">
            <div className="ranking-table-side-wrapper buy">
              <div className="ranking-table-item-wrapper">
                {steps?.[currentTick]?.ranking ? (
                  steps?.[currentTick]?.ranking.BUY.concat(
                    Array(8 - steps?.[currentTick]?.ranking.BUY?.length).fill(null)
                  ).map((item, i) => {
                    const order = orders?.find((el) => el.order_id === item);

                    return order ?
                      (!actualArray?.length || i > 0) && (!isLsHack || filteredLsOrder?.order_id !== item) && (
                        <div className={`ranking-table-item ${isMatchClassName}`} key={order?.order_id}>
                          <span
                            className={`ranking-table-item-cell buy ${isMatchClassName}`}
                          >
                            <Order
                              key={order?.order_id}
                              id={order?.order_id}
                              side={order?.side}
                              progress={order?.max_cap?.slice(0, -1)}
                              ls={order?.max_cap === "LS"}
                              isActive={true}
                              iconSize={35}
                              num={item.substr(3, 1)}
                            />
                          </span>
                        </div>
                      ) : (
                        <div className="ranking-table-item" key={i}>
                          <span className="ranking-table-item-cell"/>
                        </div>
                      );
                  })
                ) : (
                  <>
                    <div className="ranking-table-item">
                      <span className="ranking-table-item-cell"/>
                    </div>
                    <div className="ranking-table-item">
                      <span className="ranking-table-item-cell"/>
                    </div>
                    <div className="ranking-table-item">
                      <span className="ranking-table-item-cell"/>
                    </div>
                    <div className="ranking-table-item">
                      <span className="ranking-table-item-cell"/>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="ranking-table-side-wrapper sell">
              <div>
                {steps?.[currentTick]?.ranking ? (
                  steps?.[currentTick]?.ranking.SELL.concat(
                    Array(8 - steps?.[currentTick]?.ranking.SELL?.length).fill(
                      null
                    )
                  ).map((item, i) => {
                    const order = orders?.find((el) => el.order_id === item);

                    return order ?
                      (!actualArray?.length || i > 0) && (!isLsHack || filteredLsOrder?.order_id !== item) && (
                        <div className={`ranking-table-item ${isMatchClassName}`} key={order?.order_id}>
                          <span
                            className={`ranking-table-item-cell sell ${isMatchClassName} ${streamColorClassName}`}
                          >
                            <Order
                              key={order?.order_id}
                              id={order?.order_id}
                              side={order?.side}
                              progress={order?.max_cap?.slice(0, -1)}
                              ls={order?.max_cap === "LS"}
                              isActive={true}
                              iconSize={35}
                              num={item.substr(3, 1)}
                            />
                          </span>
                        </div>
                      ) : (
                        <div className="ranking-table-item" key={i}>
                          <span className="ranking-table-item-cell"/>
                        </div>
                      );
                  })
                ) : (
                  <>
                    <div className="ranking-table-item">
                      <span className="ranking-table-item-cell"/>
                    </div>
                    <div className="ranking-table-item">
                      <span className="ranking-table-item-cell"/>
                    </div>
                    <div className="ranking-table-item">
                      <span className="ranking-table-item-cell"/>
                    </div>
                    <div className="ranking-table-item">
                      <span className="ranking-table-item-cell"/>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
