import React from 'react';
import {bgColors} from '../../consts';

export const StreamDetails = ({details, id, currentTick}) => {
  const actualDetailsList = Object.keys(details)?.filter(item => details[item].tp_id.slice(2) <= currentTick + 1);

  return (
    <div className="stream-details-wrapper">
      <div className="stream-details-title">Stream
        <div style={{backgroundColor: bgColors[id]}}>{id?.slice(3)}</div>
      </div>
      <div className="stream-details-table-headers">
        <div className="ref-trade">Ref Trade</div>
        <div className="ltr">LTR</div>
        <div className="trade-fill">Trade Fill</div>
        <div className="reporting-venue">Reporting Venue</div>
      </div>
      <div className="stream-details-table">
        {
          actualDetailsList?.map(item => {
            return (
              <div key={item.id} className="stream-details-table-item">
                <div key={`${item.id}ref`} className="ref-trade">{details[item].tp_qty} - {details[item].tp_price}</div>
                <div key={`${item.id}ltr`} className="ltr">{details[item].exec_rate}%</div>
                <div key={`${item.id}trade`}
                     className="trade-fill">{details[item].exec_qty} - {details[item].exec_price}</div>
                <div key={`${item.id}reporting`} className="reporting-venue">{details[item].venue}</div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}
