import React, {useState, useEffect} from "react";
import StreamAction, {StreamClose} from "../../../svg/StreamAction";
import StreamLine from "./../StreamLine";
import {Order} from "../../common/Order/ProgressOrder";
import {useDispatch, useSelector} from "react-redux";
import {setActiveStream} from "../../../entities/actions";
import {selectorGetActiveStream} from "../../../entities/selectors";
import {hexToRgb} from "../../../utils/hexToRgba";

const Stream = (props) => {
  const {
    start,
    streamData,
    stepStreams,
    currentTick,
    tickDuration,
    simulation,
    bgcolor = "#313c5d",
  } = props;
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const [startStep, setStartStep] = useState(null);
  const [color, setColor] = useState(null);

  const [params, setParams] = useState({
    exec_rate: "",
    average_price: "",
    executed_qty: "",
  });
  const [orders, setOrders] = useState({
    buy: 0,
    sell: 0,
  });

  useEffect(() => {
    if (streamData?.length && startStep === null)
      setStartStep(streamData[0].tp - 1);
  }, [streamData]);

  useEffect(() => {
    currentTick >= startStep &&
    currentTick < streamData[streamData?.length - 1]?.tp - 1
      ? setActive(true)
      : setActive(false);
    if (!color) setColor(bgcolor);

    setParams((prev) => ({
      exec_rate: curStream?.exec_rate
        ? `${curStream?.exec_rate}%`
        : prev.exec_rate,
      average_price: curStepStream?.average_price
        ? curStepStream?.average_price
        : prev.average_price,
      executed_qty: curStepStream?.executed_qty
        ? curStepStream?.executed_qty
        : prev.executed_qty,
    }));
  }, [currentTick]);

  const curStream = streamData?.find((el) => el.tp - 1 === currentTick + 1);
  const curStepStream = stepStreams?.find(
    (e) => e.stream_id === streamData[0].sid
  );

  const activeStream = useSelector(selectorGetActiveStream);

  const showStreamsInfo = (e) => {
    if (e.target?.closest('div').id === activeStream) {
      dispatch(setActiveStream(null));
    } else {
      dispatch(setActiveStream(e.target?.closest('div').id));
    }
  };

  useEffect(() => {
    setOrders((prev) => ({
      buy: curStepStream?.orders_by_side
        ? curStepStream?.orders_by_side?.BUY?.substr(3, 1)
        : prev.buy,
      sell: curStepStream?.orders_by_side
        ? curStepStream?.orders_by_side?.SELL?.substr(3, 1)
        : prev.sell,
    }));
  }, [curStepStream]);

  return (
    <div
      className="streams-table-item streams-table-item-opened"
      style={{
        opacity: active ? 1 : 0.4,
      }}
    >
      <div className="streams-table-item-first">
        <span
          style={{
            background: `linear-gradient(90deg, ${bgcolor} 20%, #303B5A 70%)`,
          }}
        >
          <>
            <Order
              key={`${orders.sell} BUY`}
              side="BUY"
              isActive={true}
              iconSize={21}
              num={orders.buy || ""}
            />
            <div className="cover-order">
              <Order
                key={`${orders.sell} SELL`}
                side="SELL"
                isActive={true}
                iconSize={21}
                num={orders.sell || ""}
              />
            </div>
          </>
        </span>
        <span>{params?.exec_rate}</span>
        <span>{params?.average_price}</span>
        <span>{params?.executed_qty}</span>
      </div>
      <div
        className="streams-table-item-second"
        style={{
          backgroundColor: bgcolor,
        }}
      >
        <StreamLine
          start={start}
          startStep={startStep}
          active={active}
          data={streamData}
          currentTick={currentTick}
          tickDuration={tickDuration}
          simulation={simulation}
          bgcolor={bgcolor}
        />
        <div className="streams-table-item-second-actions">
          <span
            className="streams-table-item-stream-number"
            style={{
              background: active ? bgcolor : "#343F62",
              color: active ? "white" : "#888",
            }}
          >
            <div className="streams-table-item-stream-number-tint">
              Stream
              <div
                className="stream-item-number-circle"
                style={{
                  background: bgcolor,
                  color: "white",
                }}
              >
                {streamData[0]?.sid.slice(3)}
              </div>
            </div>
          </span>
        </div>
      </div>
      <div className="stream-info-wrapper" onClick={showStreamsInfo}>
        <div
          id={streamData[0]?.sid}
          className="streams-table-item-stream-action"
          style={{background: curStream?.sid ? bgcolor : hexToRgb(bgcolor, 0.3)}}
        >
          {
            activeStream === streamData[0].sid
              ? (
                <StreamClose/>
              ) : (
                <StreamAction color="#DDDDDD"/>
              )
          }
        </div>
      </div>
    </div>
  );
};
export default React.memo(Stream);
