export const SET_SIMULATION_SPEED = "SET_SIMULATION_SPEED";
export const SET_SMOOTH_ARRAY = "SET_SMOOTH_ARRAY";
export const SET_TICK_DURATION = "SET_TICK_DURATION";
export const SET_DATASET_ACTION = "SET_DATASET_ACTION";
export const SET_DRAG_DATA_ACTION = "SET_DRAG_DATA_ACTION";
export const SET_CHART_DOTS = "SET_CHART_DOTS";
export const SET_TICK = "SET_TICK";
export const RESET_APP = "RESET_APP";
export const GET_STEPS_REQUEST = "GET_STEPS_REQUEST";
export const GET_STEPS_SUCCESS = "GET_STEPS_SUCCESS";
export const GET_STEPS_ERROR = "GET_STEPS_ERROR";
export const SET_RANDOM_ORDERS = "SET_RANDOM_ORDERS";
export const SET_ORDERS_PARAMS = "SET_ORDERS_PARAMS";
export const SET_ORDER_LIMITS = "SET_ORDER_LIMITS";
export const SET_ACTIVE_STREAM = "SET_ACTIVE_STREAM";
