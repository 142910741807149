import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setOrdersParams} from "../../entities/actions";
import {OrderWithSelector} from "../common/Order/ProgressOrder/orderWithSelector.jsx";
import "./styles.css";
import {ProgressBar} from "../common/ProgressBar";

export const Orders = ({currentTick, steps, orders, simulation}) => {
  const [orderQty, setValue] = useState({});
  const [resetValue, setResetValue] = useState("");

  const dispatch = useDispatch();
  const quantityRef = React.useRef();
  const isLsChosen = orders.find((item) => item.strat === "LS");
  const streamOrders = steps[currentTick]?.orders;

  useEffect(() => {
    let newOrders = {};
    orders.forEach((order) => {
      newOrders = {
        ...newOrders,
        [order.order_id]: {size: order?.size, strat: order?.strat},
      };
    });

    setValue(newOrders);
  }, [orders]);

  const floorQuantity = (number) => Math.floor(number / 1000) * 1000;

  const orderRows = (orders = [], streamOrders = []) => {
    const color = {SELL: "#EA4444", BUY: "#70A84C"};

    const changeOrderQty = (e) => {
      if (resetValue === "") setResetValue(orderQty[e.target.id]?.size);
      setValue((state) => ({
        ...state,
        [e.target.id]: {...state?.[e.target.id], size: e.target.value},
      }));
    };

    const changeOrderCap = (e) => {
      setValue((state) => {
        const newState = {
          ...state,
          [e.target.id]: {...state?.[e.target.id], strat: e.target.value},
        };
        dispatch(setOrdersParams(newState));
        return newState;
      });
    };

    const changeSize = (e) => {
      let currentQuantity = orderQty[e.target.id]?.size;
      if (currentQuantity < 5000) {
        setValue((state) => ({
          ...state,
          [e.target.id]: {...state?.[e.target.id], size: 5000},
        }));
        return;
      }
      if (currentQuantity > 900000) {
        setValue((state) => ({
          ...state,
          [e.target.id]: {...state?.[e.target.id], size: 900000},
        }));
        return;
      }

      if (/^[1-9]+[0-9]*000$/.test(currentQuantity)) {
        dispatch(setOrdersParams(orderQty));
        setResetValue("");
      } else {
        setValue((state) => ({
          ...state,
          [e.target.id]: {...state?.[e.target.id], size: floorQuantity(currentQuantity)},
        }));
      }
    };

    const changeOnEnter = (e) => {
      if (e.keyCode === 13) {
        e.target.blur();
      }
    };

    const renderData = orders.map((order, i) => {
      const streamOrder = streamOrders?.find((e) => e.order_id === order.order_id);

      return (
        <div
          key={order.order_id}
          className="orders-table-item"
          style={{
            display: order.x ? "flex" : "none",
            opacity: streamOrder ? 1 : 0.6,
          }}
        >
          <div className="orders-table-headers-item order-id">
            <OrderWithSelector
              progress={order?.max_cap?.slice(0, -1)}
              iconSize={20}
              ls={order.max_cap === "LS"}
              marketable={streamOrder?.is_marketable}
              isActive={order.created_at_tp <= currentTick}
              num={i + 1}
              side={order.side}
              id={order.order_id}
              x={order.x}
              simulation={simulation}
            />
          </div>
          <div className="orders-table-row-item quantity">
            <span className="quantity-rest quantity-number">{streamOrder?.executed_qty || 0}</span>
            <ProgressBar
              className="quantity-progress-bar"
              completed={(streamOrder?.executed_qty / order?.ordered_qty) * 100 || 0}
              color={color[order.side]}
            />
            {simulation ? (
              <span className="quantity-value quantity-number total">{order.ordered_qty}</span>
            ) : (
              <input
                type="text"
                id={order.order_id}
                ref={quantityRef}
                value={orderQty?.[order.order_id]?.size}
                className="quantity-input quantity-number total input"
                onChange={changeOrderQty}
                onBlur={changeSize}
                onKeyUp={changeOnEnter}
              />
            )}
          </div>
          <span className="orders-table-row-item average-price">{streamOrder?.average_price || 0}</span>
          <span className="orders-table-row-item limit">{order.limit || "Market"}</span>
          <span className="orders-table-row-item stream-rate">
            <div className="orders-table-row-item quantity">
              <span className="quantity-rest quantity-number">{streamOrder?.stream_rate_total || 0}</span>
              <ProgressBar
                className="quantity-progress-bar"
                completed={
                  steps[currentTick]
                    ? (Number.parseInt(streamOrder?.stream_rate_total || 0) /
                    Number.parseInt(order.max_cap === "LS" ? 800 : order.max_cap)) *
                    100
                    : 0
                }
                color={color[order.side]}
              />
              {simulation ? (
                <span className="quantity-value quantity-number total">{order.max_cap}</span>
              ) : (
                <select
                  id={order.order_id}
                  className="quantity-input quantity-number total select select-css"
                  onChange={changeOrderCap}
                  name="max_cap"
                  value={order.strat}
                >
                  <option disabled={isLsChosen} value="LS">
                    LS
                  </option>
                  <option value="200%">200%</option>
                  <option value="30%">30%</option>
                  <option value="15%">15%</option>
                </select>
              )}
            </div>
          </span>
        </div>
      );
    });
    return renderData;
  };

  return (
    <div className="orders-wrapper">
      <div className="orders-header">
        <div className="orders-header-title">Orders</div>
      </div>
      <div className="orders-table-headers-wrapper">
        <div className="orders-table-headers">
          <span className="orders-table-headers-item order-id">Order ID</span>
          <span className="orders-table-headers-item quantity">Quantity</span>
          <span className="orders-table-headers-item average-price">Average Price</span>
          <span className="orders-table-headers-item limit">Limit</span>
          <span className="orders-table-headers-item stream-rate">Stream Rate</span>
        </div>
      </div>
      <div className="orders-table">{orderRows(orders, streamOrders)}</div>
    </div>
  );
};
