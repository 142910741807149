import React from "react";

export const LineChart = ({chartDots}) => {
  return (
    <div style={{position: "absolute", top: 0, left: 0, bottom: 0, right: 0}}>
      <svg
        width="860"
        height="232"
        viewBox="0 0 860 230"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor="black"/>
          <stop offset="100%" stopColor="white" opacity="0.5"/>
        </linearGradient>
        <polyline
          points={`0 0 ${chartDots.join(" ")} ${
            chartDots[chartDots?.length - 2]
          } 0`}
          stroke="transparent"
          fill="url(#Gradient2)"
          strokeWidth="0"
          opacity="0.2"
        />
        <polyline
          points={chartDots.join(" ")}
          stroke="white"
          fill="none"
          strokeWidth="2"
        />
        <circle cx="0" cy={chartDots[1]} r="1" stroke="white" fill="white"/>
      </svg>
    </div>
  );
};
