import React from "react";
import {setDragData} from "../../../../entities/actions";
import {useDispatch} from "react-redux";
import "./styles.css";

export const Order = ({
  progress = 0,
  iconSize,
  num,
  classname,
  id,
  isActive,
  draggable,
  side = "BUY",
  number,
  size,
  marketable,
  rate,
  ls = false,
  x,
  markers = [
    // { num: 1, left: 1, top: 1, color: "#4964c8" },
    // { num: 3, right: 1, bottom: 1, color: "#722D5F" },
  ],
  setShowLines = () => {
  },
  showLines,
  simulation,
}) => {
  const colorPattern = {
    // #2B3757 empty order
    sell: {
      gradient: "GradientRed",
      main: "",
      stroke: "rgb(200, 34, 34, 40%)",
      strokeFill: "#EA4444",
    },
    buy: {
      gradient: "GradientGreen",
      main: "",
      stroke: "rgb(34, 200, 34, 20%)",
      strokeFill: "#70A84C",
    },
  };

  const dispatch = useDispatch();

  const onDragStart = (e) => {
    e.stopPropagation();
    dispatch(
      setDragData({
        isDrag: true,
        dragOrder: e.target.id,
        side: e.target.dataset.side,
      })
    );
  };

  const onShowLines = (e) => {
    if (!simulation) return;
    if (e.target.id === showLines) return;
    if (!e.target.id) return;
    setShowLines(e.target.id);
  };

  const hideLines = (e) => {
    if (!simulation) return;
    if (e.target.id !== showLines) return;
    setShowLines("");
  };

  const orderClassName = `diagram ${side?.toLowerCase()} ${classname} ${number} ${size} ${rate}`;

  const absPos = x
    ? {
      position: "absolute",
      zIndex: 100,
      left: x,
      top: 7,
      // transition: "left 0.5s ease-out",
    }
    : {};

  const dashArray = Math.round(126 * (progress / 100));

  const lsStyles =
    ls && side === "SELL"
      ? " ls-order-sell"
      : ls && side === "BUY"
      ? " ls-order-buy"
      : " not-ls-order";

  return (
    <div
      id={id}
      key={id}
      data-side={side}
      className={orderClassName + lsStyles}
      style={{
        ...absPos,
        width: iconSize,
        height: iconSize,
        opacity: isActive || !x ? 1 : 0.5,
      }}
      onDragStart={onDragStart}
      onMouseEnter={onShowLines}
      onMouseLeave={hideLines}
      draggable={draggable}
    >
      <svg
        viewBox="0 0 40 40"
        className="svg-order"
        onMouseEnter={onShowLines}
        id={id}
      >
        <linearGradient id="GradientGreen" gradientTransform="rotate(45)">
          <stop offset="30%" stopColor="#70A84C"/>
          <stop offset="100%" stopColor="#47702D"/>
        </linearGradient>

        <linearGradient id="GradientRed" gradientTransform="rotate(45)">
          <stop offset="30%" stopColor="#EA4444"/>
          <stop offset="100%" stopColor="#A73030"/>
        </linearGradient>
        <circle
          r="20"
          cx="20"
          cy="20"
          className="circle"
          fill={colorPattern[side.toLowerCase()]?.stroke}
        />
        <circle
          r="20"
          cx="20"
          cy="20"
          strokeDasharray={`${dashArray} 126`}
          className="circle-diagrams"
          stroke={colorPattern[side.toLowerCase()]?.strokeFill}
          strokeWidth="10"
          onMouseEnter={onShowLines}
          id={id}
        />
        <circle
          r="15"
          cx="20"
          cy="20"
          className="circle"
          fill={
            marketable === false
              ? "#2B3757"
              : `url(#${colorPattern[side.toLowerCase()]?.gradient})`
          }
        />
        <text
          className="svg-text"
          x={id === "LS-Hack" ? 9 : 15}
          y="-15"
          fontFamily="Rubik-SemiBold"
          fontSize={16}
          fill="white"
        >
          {num}
        </text>
      </svg>
      {markers?.map((m, i) => {
        return (
          <div
            className={`order-stream-marker marker${id}${m.num}`}
            key={m.num + i}
            style={{
              left: m.left || "unset",
              right: m.right || "unset",
              top: m.top || "unset",
              bottom: m.bottom || "unset",
              background: m.color,
            }}
          >
            {m.num}
          </div>
        );
      })}
    </div>
  );
};
