import React from 'react';
import './styles.css';

export const ProgressBar = ({color, completed, className}) => {
  const fillerStyles = {
    width: `${completed}%`,
    backgroundColor: color,
  };

  const wrapperClassName = className
    ? `${className} progress-bar-wrapper`
    : "progress-bar-wrapper"

  return (
    <div className={wrapperClassName}>
      <div className="progress-bar-body">
        <div style={fillerStyles} className="progress-bar-inner"/>
      </div>
    </div>
  );
};