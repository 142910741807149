import React from "react";
import "./styles.css";

export const Button = ({text, onClick, inactive, footer, icon}) => {
  const btnClass = `standard-button ${inactive ? "inactive-button" : ""} ${
    footer ? "footer-btn" : ""
  }`;
  const data = () => {
    switch (icon?.position) {
      case 'left':
        return (
          <button className={btnClass}
                  style={icon.color && {background: 'linear-gradient(0deg, #334463, #334463), #E8836D'}}
                  onClick={onClick}>
            {icon.src()}
            {text}
          </button>
        )
      case 'right':
        return (
          <button className={btnClass}
                  style={icon.color && {background: 'linear-gradient(0deg, #334463, #334463), #E8836D'}}
                  onClick={onClick}>
            {text}
            {icon.src()}
          </button>
        )
    }
  }

  return (
    icon ? (
      data()
    ) : (
      <button className={btnClass} onClick={onClick}>
        {text}
      </button>
    )
  )
};
