import React from 'react';

export const PrevIcon = () => {
  return (
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg"
         style={{marginRight: 5 + 'px'}}>
      <path d="M9 8L6 4.5L9 1" stroke="#4980D9" strokeWidth="2"/>
      <path d="M5 8L2 4.5L5 1" stroke="#4980D9" strokeWidth="2"/>
    </svg>
  );
}

export const NextIcon = () => {
  return (
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg"
         style={{marginLeft: 5 + 'px'}}>
      <path d="M1 8L4 4.5L1 1" stroke="#4980D9" strokeWidth="2"/>
      <path d="M5 8L8 4.5L5 1" stroke="#4980D9" strokeWidth="2"/>
    </svg>
  );
}

export const PauseIcon = () => {
  return (
    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg"
         style={{marginRight: 7 + 'px'}}>
      <rect width="3" height="10" rx="1.5" fill="white"/>
      <rect x="5" width="3" height="10" rx="1.5" fill="white"/>
    </svg>
  );
}

export const PlayIcon = () => {
  return (
    <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg"
         style={{marginRight: 7 + 'px'}}>
      <path
        d="M8.5 4.63397C9.16667 5.01888 9.16667 5.98113 8.5 6.36603L1.75 10.2631C1.08333 10.648 0.25 10.1669 0.25 9.39711L0.250001 1.60289C0.250001 0.833085 1.08333 0.35196 1.75 0.73686L8.5 4.63397Z"
        fill="white"/>
    </svg>
  );
}


