import React from "react";
import "./styles.css";
import {Button} from "../common/Button";

export const FooterButtons = ({replay, reset}) => {
  const sendMail = () => {
    window.location.href = "mailto:questions@purestream.tech?subject=Purestream question";
  };
  const playNew = () => reset();
  return (
    <div className="timetable-header">
      <span className="footer-title">Do you have any questions?</span>
      <div>
        <div className="drag-caption">
          <Button text={"Send message"} footer onClick={sendMail}/>
          <Button text={"Replay this scenario"} footer onClick={replay}/>
          <Button text={"New Simulation"} footer onClick={playNew}/>
        </div>
      </div>
    </div>
  );
};
