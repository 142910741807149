import React, {useState, useEffect} from "react";
import {LineChart} from "../SVGChart";
import LineTo from "react-lineto";
import {Order} from "../common/Order/ProgressOrder";
import {setDragData} from "../../entities/actions";
import {useDispatch, useSelector} from "react-redux";
import {bgColors} from '../consts';

export const TimeTableGrid = ({
  chartArray,
  simulationSpeed,
  chartDots,
  simulation,
  steps,
  currentTick,
  activeOrders = {},
}) => {
  const [showLines, setShowLines] = useState("");
  const [lines, setLines] = useState([]);
  const screenData = useSelector((state) => state.init.screenData);
  const orders = useSelector((state) => state.init.orders);
  const pointStep = useSelector((state) => state.init.pointStep);
  const dragState = useSelector((state) => state.init.dragState);
  const streamOrders = steps[currentTick]?.orders;
  const streams = steps[currentTick]?.streams;

  const dispatch = useDispatch();
  const table = React.useRef();

  useEffect(() => {
    if (showLines === "") {
      setLines([]);
      return;
    }
    const lines = streams
      ?.map((e) =>
        Object.values(e.orders_by_side).map((el) => el + e.stream_id?.slice(3))
      )
      .filter((e) => e.some((el) => el?.slice(0, 4) === showLines));
    if (lines?.length) setLines(lines);
  }, [showLines]);

  const onDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {target} = e;
    dispatch(setDragData({target: target.closest(".limit-line").id}));
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const getXPos = (x, pointStep, orders = []) => {
    let xPos = Math.round(
      (x % pointStep) - pointStep / 2 >= 0
        ? x + pointStep - (x % pointStep)
        : x - (x % pointStep)
    );

    if (
      orders
        .filter((e) => e.order_id !== dragState.dragOrder)
        .some(
          (e) => e.x >= xPos - pointStep * 0.8 && e.x <= xPos + pointStep * 0.8
        ) &&
      xPos + pointStep < 860
    )
      do {
        xPos = xPos + pointStep;
        if (xPos + pointStep >= 860) xPos = 500;
      } while (
        orders
          .filter((e) => e.order_id !== dragState.dragOrder)
          .some(
            (e) =>
              e.x >= xPos - pointStep * 0.8 && e.x <= xPos + pointStep * 0.8
          )
        );

    if (
      orders
        .filter((e) => e.order_id !== dragState.dragOrder)
        .some(
          (e) =>
            e.x >= xPos - 40 && e.x <= xPos + 40 && dragState.target === e.row
        ) &&
      xPos + 40 < 860
    )
      do {
        xPos = xPos + pointStep;
        if (xPos + pointStep >= 860) xPos = 500;
      } while (
        orders
          .filter((e) => e.order_id !== dragState.dragOrder)
          .some(
            (e) =>
              e.x >= xPos - 40 && e.x <= xPos + 40 && dragState.target === e.row
          )
        );

    return xPos;
    // const getTP = (x, pointStep) =>  x % pointStep - pointStep / 2 >= 0 ? x + pointStep - x % pointStep : x - x % pointStep
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const xPos = getXPos(
      e.clientX - table.current.offsetLeft,
      pointStep,
      orders
    );
    dispatch(setDragData({isDrag: false, offsetX: xPos}));
  };

  const renderOrderSpots = (
    screenData,
    orders,
    currentTick,
    line,
    simulation
  ) => {
    return orders.map((order, i) => {
      const actualOrders = steps[currentTick]?.orders;
      const markers =
        Object.keys(activeOrders[order.order_id] || {})?.map((el, i) => ({
          num: el.slice(3), // activeOrders[order.order_id][el],
          id: el,
          right: i % 2 ? 1 : "unset",
          left: i % 2 ? "unset" : 1,
          top: i > 1 ? "unset" : 1,
          bottom: i <= 1 ? "unset" : 1,
          color: bgColors[el],
        })) || [];

      return order.row === line ? (
        <Order
          progress={order?.strat.slice(0, -1)}
          ls={order.strat === "LS"}
          key={order.order_id + order.row}
          id={order.order_id}
          side={order.side}
          marketable={
            streamOrders?.find((e) => e.order_id === order.order_id)
              ?.is_marketable
          }
          iconSize={40}
          num={i + 1}
          x={order.x - 17}
          isActive={order.created_at_tp <= currentTick && actualOrders && actualOrders[i]?.executed_qty < order.size}
          draggable={!simulation}
          markers={markers}
          setShowLines={setShowLines}
          showLines={showLines}
          simulation={simulation}
        />
      ) : (
        <React.Fragment key={i}></React.Fragment>
      );
    });
  };

  return (
    <div className="timetable-grid" ref={table}>
      <div
        id="line1"
        className="limit-line one dark"
        onDragEnter={onDragEnter}
        onDragOver={
          dragState?.side?.toLowerCase() === "buy" ? onDragOver : null
        }
        onDrop={onDrop}
      >
        {renderOrderSpots(screenData, orders, currentTick, "line1", simulation)}
      </div>
      <div className="limit-line-divider one"/>
      <div
        id="line2"
        className="limit-line two light"
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        {renderOrderSpots(screenData, orders, currentTick, "line2", simulation)}
      </div>
      <div className="limit-line-divider two"/>
      <div
        id="line3"
        className="limit-line three light"
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        {renderOrderSpots(screenData, orders, currentTick, "line3", simulation)}
      </div>
      <div className="limit-line-divider three"/>
      <div
        id="line4"
        className="limit-line four dark"
        onDragEnter={onDragEnter}
        onDragOver={
          dragState?.side?.toLowerCase() === "sell" ? onDragOver : null
        }
        onDrop={onDrop}
      >
        {renderOrderSpots(screenData, orders, currentTick, "line4", simulation)}
      </div>
      {simulation && chartDots?.length && (
        <LineChart
          chartArray={chartArray}
          simulationSpeed={simulationSpeed}
          chartDots={chartDots}
        />
      )}
      {showLines && (
        <>
          {lines?.map((e) => (
            <LineTo
              from={`marker${e[0]}`}
              to={`marker${e[1]}`}
              borderStyle="dashed"
              borderColor={bgColors[`SID${e[0].slice(4)}`]}
              borderWidth="2"
              zIndex={99}
              delay={100}
            />
          ))}
        </>
      )}
    </div>
  );
};
