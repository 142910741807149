import React, {useState} from "react";
import {Order} from "./index.jsx";
import {useDispatch} from "react-redux";
import "./styles.css";
import {orderBy} from "lodash";

export const OrderWithInfo = (props) => {
  const {side, size, strat} = props;

  const [hideInfo, setHideInfo] = useState(false);

  const hideInfoBlock = () => setHideInfo(true);
  const restoreInfoBlock = () => setHideInfo(false);

  return (
    <div
      className="timetable-order-wrapper"
      onMouseDown={hideInfoBlock}
      onMouseUp={restoreInfoBlock}
      onMouseLeave={restoreInfoBlock}
    >
      <Order {...props} />
      {!hideInfo && (
        <div className="order-infoblock-wrapper">
          <div className="order-info-item">
            <span className="order-info-item-title">Side:</span>
            <span
              className="order-info-item-data"
              style={{color: side === "sell" ? "#DE6767" : "#70A84C"}}
            >
              {side.toUpperCase()}
            </span>
          </div>
          <div className="order-info-item">
            <span className="order-info-item-title">Quantity:</span>
            <span className="order-info-item-data">{size}</span>
          </div>
          <div className="order-info-item">
            <span className="order-info-item-title">Order type:</span>
            <span className="order-info-item-data">{strat}</span>
          </div>
        </div>
      )}
    </div>
  );
};
