import React from "react";
import "./styles.css";
import {Button} from "../common/Button";
import Drag from "../../svg/Drag";
import {setDragData} from "../../entities/actions";
import {useDispatch, useSelector} from "react-redux";
import {OrderWithInfo} from "../common/Order/ProgressOrder/orderWithInfo.jsx";
import {TimeTableGrid} from "./TimeTableGrid";
import {PrevIcon, NextIcon, PauseIcon, PlayIcon} from "../../svg/Nav";

export const Timetable = ({
  chartArray,
  simulationSpeed,
  chartDots,
  simulation,
  start,
  steps,
  currentTick,
  randomize,
  stepBack,
  stepForward,
  startStop,
  maxMin,
  activeOrders,
}) => {
  const orders = useSelector((state) => state.init.orders);
  const limits = useSelector((state) => state.init.limits);
  const dispatch = useDispatch();

  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    /*  console.log(
      "e",
      e.nativeEvent.path?.find((el) => el.classList.contains("orders-choose"))
        .id
    ); */
    if (e.nativeEvent.path?.find((el) => el.classList.contains("orders-choose")).id === "baseplate") {
      dispatch(setDragData({isDrag: false, offsetX: null}));
    }
  };

  const isReadyToSimulation = (orders) => {
    const activeOrders = orders.filter((e) => e.x);
    return (
      activeOrders.length >= 2 &&
      activeOrders.some((e) => e.side === "BUY") &&
      activeOrders.some((e) => e.side === "SELL")
    );
  };

  return (
    <div className="timetable-wrapper">
      <div id="baseplate" className="orders-choose" onDragOver={onDragOver} onDrop={onDrop}>
        {simulation ? (
          <div className="limits-wrapper">
            <div className="upper-market">{/* {maxMin?.max.toFixed(2)} */ "-"}</div>
            <div className="upper-limit">{limits?.upper.toFixed(2)}</div>
            <div className="lower-limit">{limits?.lower.toFixed(2)}</div>
            <div className="lower-market">{/* {maxMin?.min.toFixed(2)} */ "-"}</div>
          </div>
        ) : (
          orders?.length &&
          [...orders]
            .sort((a, b) => (a.order_id < b.order_id ? -1 : 1))
            .map((order, i, arr) => {
              return !order.row ? (
                <OrderWithInfo
                  key={order.order_id}
                  classname="orders-choose-item"
                  iconSize={35}
                  id={order.order_id}
                  side={order.side.toLowerCase()}
                  size={order.size}
                  strat={order.strat}
                  ls={false}
                  draggable={!simulation}
                />
              ) : (
                <div className="settle-wrapper" key={i}>
                  <div key={order.order_id} className="order-settle"/>
                </div>
              );
            })
        )}
      </div>

      <div className="timetable-divider"></div>
      <div className="timetable">
        <div className="timetable-header">
          <span className="title">Scenario Grid</span>
          <div>
            {simulation ? (
              <div className="drag-caption">
                <Button
                  text={"Prev"}
                  icon={{src: PrevIcon, position: "left", color: "dark"}}
                  onClick={stepBack}
                  inactive={start}
                />
                <Button
                  text={start ? "Pause" : "Continue"}
                  icon={start ? {src: PauseIcon, position: "left"} : {src: PlayIcon, position: "left"}}
                  onClick={startStop}
                />
                <Button
                  text={"Next"}
                  icon={{src: NextIcon, position: "right", color: "dark"}}
                  onClick={stepForward}
                  inactive={start}
                />
              </div>
            ) : (
              <div className="drag-caption">
                <Drag/>
                <div style={{marginLeft: "5px"}} className="drag-text">
                  Drag
                </div>
                <div style={{margin: "0 5px"}}>the orders in the Scenario Grid</div>
                <div style={{margin: "0 4px 0 4px"}}>or</div>
                <Button
                  text={isReadyToSimulation(orders) ? "Start simulation" : "Randomize orders"}
                  onClick={isReadyToSimulation(orders) ? startStop : randomize}
                />
              </div>
            )}
          </div>
        </div>
        <TimeTableGrid
          chartArray={chartArray}
          steps={steps}
          simulationSpeed={simulationSpeed}
          chartDots={chartDots}
          simulation={simulation}
          currentTick={currentTick}
          activeOrders={activeOrders}
        />
      </div>
      <div className="limits">
        <div className="limits-item">Market Buy</div>
        <div className="limits-item">+15 BPS</div>
        <div className="limits-item">-15 BPS</div>
        <div className="limits-item">Market Sell</div>
      </div>
    </div>
  );
};
