import React, {useState, useEffect} from "react";
import "./styles.css";

const sipData = new Array(100).fill("").map((e, i) => ({price: 16 + i, quantity: 200 + i}));

const SipLine = ({tickDuration}) => {
  const [left, setLeft] = useState(-675);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (tickDuration) {
      setLeft(-675);
      setReset(true);
    }
  }, [tickDuration]);

  useEffect(() => {
    if (left === -675 && reset) {
      setTimeout(() => {
        setLeft(-675 - 135 * sipData.length);
        setReset(false);
      }, 20);
    }
  }, [left, reset]);

  return (
    <div
      className="streams-streamline__wrapper"
      style={{
        backgroundColor: "#383B61",
        transition: "background-color 1s ease-out, opacity 1s ease-out",
      }}
    >
      <div
        className="streams-streamline__innerwrapper"
        style={{
          left,
          transition: `left ${left !== -675 ? tickDuration * sipData.length : 0}ms linear`,
        }}
      >
        {[<div style={{width: 672}}/>].concat(
          sipData?.map((item, index) => (
            <div key={index} className="streamline-item">{`${item.quantity} - ${item.price}`}</div>
          ))
        )}
      </div>
    </div>
  );
};

export default SipLine;
