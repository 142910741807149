import React from 'react';

export default function SliderIcon() {
  return (
    <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg"
         style={{marginBottom: 8 + 'px'}}>
      <rect width="1" height="5" fill="#EFEFEF"/>
      <rect x="2" width="1" height="5" fill="#EFEFEF"/>
      <rect x="4" width="1" height="5" fill="#EFEFEF"/>
    </svg>
  );
}
